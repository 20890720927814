import React from "react"
import Layout from "../components/Layout/Layout"

import "./grain.scss"

function grain() {
  return (
    <Layout>
      <div className="grain">
        <h2>Оптовая торговля</h2>
        <a
          href="https://drive.google.com/file/d/1oXmZvyHToFFmByYBm9G7ZleQ5hFI73yS/view?usp=sharing"
          target="_blank"
          rel="noreferrer"
        >
          ПРАЙС-ЛИСТ
        </a>
        <br />
        <a
          href="https://drive.google.com/file/d/1-1tuXkXprxTwsF0DORIc7dSPQdR-Ilyh/edit?usp=share_link&ouid=107365724329502767170&rtpof=true&sd=true"
          target="_blank"
          rel="noreferrer"
        >
          Сертификат глицерин
        </a>
        <a
          href="https://drive.google.com/file/d/1-8hEJyXa6smFQTXCvvNMJxeAO6mpcxHh/edit?usp=sharing&ouid=107365724329502767170&rtpof=true&sd=true"
          target="_blank"
          rel="noreferrer"
        >
          Сертификат пропиленгликоль
        </a>
        <div className="grid">
          {/* <div className="card">
            <h3>Комбикорм полнорационный ПК-1-15</h3>
            <span>
              Для кур яичных кроссов свыше 40 до 60 недель включительно
              <p>СТБ 1842-2008</p> обеспечивает высокую яйценоскость без
              дополнительного подкармливания (280 яиц в год); гарантирует
              получение яиц с прочной скорлупой; повышает показатели
              воспроизводства и инкубации; помогает выработать иммунитет против
              болезней и инфекций;
              <br /> обеспечивает птицу жизненно важными для нее веществами и
              витаминам;
              <br /> предупреждает авитаминозы и болезни, вызванные недостатком
              микроэлементов и витаминов;
              <br /> предупреждает массовые желудочно-кишечные заболевания;
              <br /> содержит необходимое количество наиболее важных
              микроэлементов для жизнедеятельности птицы:
              <br />
              кальция (от полноценного содержания которого зависит состояние
              костной системы птицы и формирование скорлупы);
              <br /> фосфора (нормализует обмен кальция, препятствует развитию
              рахита у молодняка);
              <br /> натрия (повышает качество скорлупы, контролирует
              фосфорно-кальциевый обмен). <h4>Состав комбикорма</h4>
              <p>
                Зерновая группа 65% (кукуруза, тритикале, ячмень шелушенный,
                пшеница, рожь)
              </p>
              Белковая группа 25% (шрот подсолнечный, шрот соевый, жир, рыбная
              мука, мясокостная мука, дрожжи)
              <p>
                Минерально-витаминная группа 10% (мел, фосфат, поваренная соль,
                премикс)
              </p>
              <h4>Качественные показатели</h4>
              Обменная энергия: 11,63 МДж/кг
              <br /> Сырой протеин: не менее 16,5 %.
              <br />
              Сырая клетчатка: не более 5,5 %.
              <br /> Лизин: не менее 0,77%
              <br /> Метионин + цистин: не менее 0,68%
              <br /> Триптофан: не менее 0,17%
              <br /> Кальций: не менее 3,50%.
              <br /> Фосфор: не более 0,70 %.
              <br /> Натрий: не более 0,20 %.
              <p>Форма: гранулы, экспандировано-гранулированная крупка.</p>
              <p>Упаковка: полипропиленовые 30 кг.</p>
              <p>Сроки хранения: 1 месяц с момента изготовления.</p>
            </span>
          </div>
          <div>
            <div className="card">
              <h3>ШРОТ ПОДСОЛНЕЧНЫЙ</h3>
              <span>
                Протеин, не менее 39 % в пересчетена а.с.в.;
                <br />
                Массовая доля сырого жира, 1,5 % в пересчете на а.с.в.;
                <br />
                Сырая клетчатка, не более 23 % в пересчете на а.с.в.;
                <br />
                Массовая доля влаги и летучих веществ, 9-11 %;
                <br />
                Массовая доля влаги и летучих веществ, 9-11 %;
                <br />
                <p>
                  Содержание пестицидов, микотоксинов, металлов, согласно
                  ветеринарно-санитарным нормативам Республики Беларусь. Срок
                  хранения: в надлежащих условиях - 3 месяца (насыпью), в мешках
                  до 1 года. Производства Украины и России.
                </p>
              </span>
            </div>

            <div className="card last">
              <h3>ДРОЖЖИ КОРМОВЫЕ ГОСТ</h3>
              <span>
                Массовая доля влаги, не более 10,0%;
                <br />
                Массовая доля сырого протеина в пересчете на а.с.в. "не менее -
                43,0 %";
                <br />
                Массовая доля белка по Барнштейну в пересчете на а.с.в., "не
                менее 32,0 %";
                <br />
                Наличие живых клеток продуцента - Отсуствуют;
                <br />
                Токсичность - Нетоксично;
                <br />
                Массовая доля золы в пересчете на а.в.с., не более - 10,0%;
                <br />
                <p>
                  Продукция отгружается в полипропиленовых мешках по 30-35 кг,
                  биг-бэгах до 1 000 кг, а также в бумажных мешках до 30 кг, на
                  паллетах. Срок хранения: 6 месяцев со дня производства.
                </p>
              </span>
            </div>
            <div className="card last">
              <h3>Сухие пивные дрожжи неактивные</h3>
              <h4>ТУ BY193357461.001-2020</h4>
              <span>
                <h4>Состав:</h4>
                <p>
                  Пивные дрожжи неактивные сухие. Форма выпуска: порошок
                  Гарантируемые показатели: Массовая доля сырого протеина, не
                  менее – 40 % Массовая доля влаги, не более – 10 % Патогенные
                  микроорганизмы, в том числе сальмонелла в 25 г. – Не
                  допускаются. Энергетическая ценность: 215,6 ккал., ( 902,0
                  кДж.) Питательная ценность: Белки 40 г., Жиры 1,2 г., Углеводы
                  10,2 г.
                </p>
                <p>
                  Масса нетто: 25 кг. Упаковка: мешок с полиэтиленовым
                  вкладышем. № партии: Дата выпуска: Срок годности: 24 месяца с
                  даты изготовления. Хранить: в сухом, защищенном от света месте
                  при температуре не выше 25ºС
                </p>
              </span>
            </div>
          </div>
          <div className="card">
            <h3>ШРОТ СОЕВЫЙ</h3>
            <span>
              Шрот соевый рекомендован для введения в корма птиц, рыб и
              животных, так как по своему происхождению близок к животным
              белкам. При добавлении соевого шрота в комбикорм наблюдается
              увеличение суточного роста животных, увеличивается качество молока
              и надой, питательные вещества, содержащиеся в корме, усваиваются
              значительно лучше, что положительно влияет на общее состояние
              животных. Качественные показатели:
              <br />
              - Протеин на с.в. 50 % на а.с.в.
              <br />
              - Массовая доля жира 2%
              <br />- Клетчатка на с.в. 9%
            </span>
          </div>
          <div className="card">
            <h3>Комбикорм-концентрат КК-61С</h3>
            <span>
              Для молочных коров продуктивностью свыше 6000кг. в стойловый
              период.
              <p>ГОСТ 9268-2015</p>
              высокую продуктивность животных и нормальное воспроизводство;
              <br />
              позволяет получать стабильно высокие удои на протяжении многих
              лактаций;
              <br />
              обеспечивает получение молока высокого качества;
              <br />
              помогает получить высокое содержание жира;
              <br />
              При кормлении коров по рекомендуемому рациону надои достигают до
              8000 литров в год.
              <h4>Состав комбикорма</h4>{" "}
              <p>Зерновая группа (тритикале, ячмень, пшеница, овес, рожь)</p>{" "}
              <p>
                Белковая группа (шрот подсолнечный, шрот соевый, дрожжи
                кормовые, шрот рапсовый, жмых рапсовый)
              </p>
              <p>
                Минерально-витаминная группа (поваренная соль, мел, фосфат,
                премикс П60-3, меласса)
              </p>
              <h4>Качественные показатели</h4> Обменная энергия: не менее 11
              МДж/кг.
              <br /> Кормовые единицы в 1 кг: не менее 1.0.
              <br /> М.д. сырого протеина: не менее 20%.
              <br /> М.д. сырого жира: не менее 5%.
              <br /> М.д. сырой клетчатки: не более 6%.
              <br /> М.д. крахмала+сахара: 25-35%.
              <br /> М.д. лизина: не менее 0,75%.
              <br /> М.д. метионина+цистита: не менее 0,45%.
              <br /> М.д. кальция: 0,6-0,85%.
              <br /> М.д. фосфора: 0,85-1,0%.
              <br /> М.д. хлорида натрия: 1,0-1,5%.
              <br />
              <p>
                Форма: гранулы, гранулированый или экспандировано-гранулированый
                вид.
              </p>
              <p>
                Упаковка: мешки полипропиленовые 30 кг, мешки полипропиленовые
                25 кг.
              </p>
              <p>Срок хранения: 2 месяца с момента изготовления.</p>
            </span>
          </div>
          <div className="card">
            <h3>Комбикорм-концентрат КК-55</h3>
            <span>
              Для откорма свиней до жирных кондиций
              <p>ГОСТ 9267-68</p>
              удовлетворяет потребность животного в энергии и питательных
              веществах, микроэлементах и витаминах; регулирует обмен веществ;
              <br />
              среднесуточный привес составляет 600 г.
              <h4>Состав комбикорма</h4>
              <p>
                Зерновая группа 85% (тритикале, ячмень, отруби пшеничные,
                зерносмесь, рожь, кукуруза)
              </p>
              <p>Белковая группа 10% (шрот подсолнечный, дрожжи, люпин)</p>
              <p>
                Минерально-витаминная группа 5% (поваренная соль, мел, фосфат,
                премикс)
              </p>
              <h4>Качественные показатели</h4> Кормовые единицы: не менее 0,85.
              <br />
              Влажность: не более 14,5%.
              <br /> Сырой протеин: не менее 11%.
              <br /> Сырая клетчатка: не более 10%.
              <br />
              <p>Форма: гранулы, крупка или экспандированная крупка.</p>
              <p>Упаковка: мешки полипропиленовые, 30 кг.</p>
              <p>Срок хранения: 2 месяца с момента изготовления.</p>
            </span>
          </div> */}
          <div className="card">
            <h3>Альбит</h3>
            Альбит® – мировой стандарт антистрессовой защиты, первый антидот
            биологического происхождения, успешно применяемый земледельцами 50
            регионов России и 25 стран мира.
            <ul>
              <li>Защищает растения от засухи и других природных стрессов</li>
              <li>
                Снимает побочное стрессовое действие пестицидов: в результате
                они работают «на 100 %»
              </li>
              <li>
                «Мировой стандарт антистрессовой защиты»: 20 лет успешного
                применения в 50 регионах России и 25 странах Мира
              </li>
              <li>
                Повышает эффективность фунгицидов: гарантированная защита от
                болезней
              </li>
              <li>
                Оздоравливает почву, повышает вынос элементов питания из
                удобрений
              </li>
              <li>
                В результате – урожайность и качество урожая с Альбитом на 10-30
                % выше, чем при использовании пестицидов без Альбита (данные по
                70 с/х культурам)
              </li>
              <li>Более раннее получение полноценного урожая</li>
            </ul>
            Альбит® – мировой стандарт антистрессовой защиты, современный
            инновационный полифункциональный препарат «3 в 1» (антидот,
            регулятор роста, фунгицид), назначение которого: повышение
            устойчивости растений к засухе и другим неблагоприятным факторам
            среды (стрессам), нейтрализация стрессового действия химических
            пестицидов и удобрений, повышение полевой всхожести семян,
            сокращение периода, необходимого растениям на формирование урожая,
            увеличение урожайности (на 5-30 % в зависимости от культуры),
            улучшение качества продукции (содержание клейковины у пшеницы в
            среднем на 1,6 абс.% (2,3 % к контролю), товарность картофеля,
            овощей и фруктов), защита растений от ряда болезней (корневые гнили,
            листовые пятнистости, мучнистая роса, ржавчины, фитофтороз,
            бактериозы). Добавление Альбита в баковую смесь обеспечивает
            усиленный, стабильный и бесстрессовый эффект химических пестицидов в
            широком диапазоне фитосанитарных, почвенно-агрохимических и погодных
            условий.
            <ul>
              <li>
                За счёт антистрессового действия, Альбит повышает урожайность
                зерновых на 2,9-10,7 ц/га, сахарной свёклы на 48,1 ц/га,
                подсолнечника на 3,4 ц/га, картофеля на 34,3 ц/га, сои на 3,2
                ц/га, зерна кукурузы на 3,7 ц/га, рапса на 3,8 ц/га, зелёной
                массы кормовых трав на 20-110 ц/га**. Прибавка урожая в 2,25
                раза выше, чем в среднем у регуляторов роста и биопрепаратов
              </li>
              <li>
                Повышает качество урожая (клейковина у пшеницы, товарность
                картофеля, овощей, фруктов и виноград)
              </li>
              <li>Увеличивает всхожесть семян, ускоряет созревание урожая</li>
              <li>
                Защищает растения от болезней (корневые гнили, бурая ржавчина,
                мучнистая роса, фитофтороз, бактериозы), усиливает эффект
                химических фунгицидов. Не вызывает привыкания у фитопатогенов.
                Оздоровляет почву, снижает необходимость в использовании
                удобрений
              </li>
              <li>Экологичен (4 класс опасности), отсутствует срок ожидания</li>
              <li>
                Малорасходный. Добавление Альбита, практически не повышая
                стоимость обработки, обеспечивает гарантированно высокий,
                беcстрессовый эффект пестицидов в любых погодных и
                почвенно-агрохимических условиях
              </li>
              <li>
                Эффективность подтверждена в более чем 500 полевых опытах на 70
                сельскохозяйственных культурах. Стабильность действия Альбита в
                среднем в 2 раза выше, чем у аналогов. Служит земледельцам уже
                более 20 лет
              </li>
              <li>
                В отличие от других стимуляторов, Альбит является полноценным
                антидотом (снимает фитотоксическое действие гербицидов на
                культурные растения и не влияет на гербицидные свойства по
                отношению к сорным). Защищён патентами «Антидотная композиция
                биологического происхождения для использования в
                растениеводстве» и «Препарат для повышения урожая растений и
                защиты их от фитопатогенов»
              </li>
            </ul>
          </div>
          <div className="card">
            <h3>Глицерин</h3>
            <p>
              При кормлении коров рекомендуется применять энергетические
              добавки, которые способствуют восстановлению организма животного
              после удоя. Одной из таких добавок является глицерин – трехатомный
              спирт, который раньше добывали при омылении жиров (в производстве
              мыла), а сегодня его получают при производстве биодизельного
              топлива и в нефтехимической промышленности.
            </p>
            <p>
              В животноводческой отрасли глицерин используется в качестве
              энергетической добавки для крупного рогатого скота. Также может
              применяться с теми же целями для кормления птицы и свиней.
            </p>
            <p>
              При этом возможно использование глицерина для животных как в
              чистом (дистиллированном виде), с чистотой 99,5%, так и при
              смешивании с основным питанием. Глицерин имеет сладкий вкус,
              поэтому животные потребляют его с удовольствием. Пищевая добавка
              глицерин необходима для поддержания нормального уровня глюкозы в
              крови, и позволяет нормализовать энергетический обмен. Также
              вещество позволяет снизить объем жирных кислот в крови, что
              защищает от заболевания «жирной» печени и от кетоза (нарушения
              обмена веществ, которое сопровождается расстройством пищеварения).
            </p>
            <p>
              Глицерин используют в качестве кормовой добавки для крупного
              рогатого скота в конце сухостойного периода и сразу после отела (в
              период лактации) в течение трех месяцев, так как он способствует
              ускоренному восстановлению репродуктивной функции, нормализует
              гормональный фон, улучшает надои и жирность молока.
            </p>
            <p>
              Специалисты рекомендуют добавлять глицерин для КРС в утренний
              комбикорм. Нормы потребления глицерина: за неделю до отела и через
              14 дней после – 200 г в день для одной коровы; затем – по 150 г в
              день. Использование глицерина улучшает состояние здоровья
              животного, благодаря чему увеличиваются и надои молока.
            </p>
            <p>
              При поступлении в организм кормовая добавка для КРС всасывается из
              рубца, или преобразуется в пропионовую и масляную кислоты. При
              соблюдении нормативов по качеству концентрация глицерина в
              комбикорме может составлять до 5% без вреда для здоровья
              животного.
            </p>
            <p>
              Глицерин имеет не только энергетическую ценность, а также обладает
              полезными физическими качествами, которые существенно упрощают
              процесс производства кормов. Так, вещество хорошо удерживает воду
              и стабилизирует количество воды в комбикорме, защищая его от
              высыхания. При этом может смешиваться с водой в любых пропорциях.
            </p>
            <p>
              Другое физическое свойство глицерина – его высокая плотность (1,26
              гр/куб. см при 20°С), благодаря которой он имеет оптимальную
              текучесть и хорошо смешивается с твердыми компонентами кормовой
              смеси. Однако из-за своей плотности вещество быстро загустевает и
              может даже затвердевать в условиях ниже +12°С, поэтому
              использовать его зимой рекомендуется только в отапливаемых
              помещениях, где он не кристаллизуется.
            </p>
            <p>
              Глицерин может использоваться для добавления в корм животных в
              чистом виде или сыром. Чистый глицерин – это сырье с чистотой 99%.
              Сырой глицерин – это продукт, в котором содержится от 80% чистого
              вещества, а остальное – это другие компоненты: сырая зола – не
              более 8%, натрий – не более 4%, калий – не более 4%, вода – не
              более 15%.
            </p>
            <p>
              Согласно правилам применения кормовых смесей, с учетом назначения
              глицерин может использоваться как технологическая добавка или как
              отдельная добавка без использования других веществ. Возможно его
              применения для любых разводимых животных, главное – учитывать
              пропорции, чтобы корм имел хорошую эффективность без снижения
              производительности.
            </p>
            <p>
              По нормам, возможно применение глицерина для кормления животных,
              который произведен из растительных масел и жиров.
            </p>
          </div>
          <div className="card">
            <h3>Пропиленгликоль</h3>
            <p>
              Пропиленгликоль для сельского хозяйства по виду это жидкость
              вязкой консистенции без цвета со слабым запахом и сладковатым
              вкусом. Другое название вещества – 1,2-пропандиол.
            </p>
            <p>
              Жидкость хорошо растворяет различные спирты, сочетается с
              кислотами, эфирами и другими сложными соединениями. Смешивается с
              водой, причем выделяется гигроскопичностью – не позволяет влаге
              испариться. Не растворяется в маслах и жирах.
            </p>
            <p>
              Пропиленгликоль в животноводстве применяется для производства
              энергетических добавок, которые используются в корме для скота.
              Вещество всасывается рубцом животного и через кровь поступает в
              печень, где применяется для синтеза глюкозы – источника энергии.
              Это способствует улучшению обмена веществ, защищает от различных
              заболеваний.
            </p>
            <p>
              Кроме восполнения недостатка энергии и нормализации
              углеводно-жирового обмена добавки с использованием пропиленгликоля
              поддерживают баланс катионов и анионов, улучшают продуктивность
              коров и качество надоев.
            </p>
            <p>
              Пропиленгликоль в сельском хозяйстве может использоваться не
              только в качестве добавки к сухим кормам, но и в жидких
              энергетических кормах. Но важно использовать пропиленгликоль для
              крупного рогатого скота именно в добавках, так как в чистом виде
              он усваивается организмом животного не полностью.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default grain
